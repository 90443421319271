<template>
  <c-box class="container">
    <Header />

    <c-box
      max-w="1200"
      mx="auto"
      mt="30px"
    >
      <Breadcrumb :items="breadcrumbItems" />
      <hr
        v-chakra
        mt="20px"
      >

      <c-flex
        mt="10"
        mb="50"
      >
        <c-box
          mx="auto"
          w="800px"
        >
          <c-tabs>
            <c-tab-list>
              <c-tab>
                <c-text>Detail Progress &amp; Tracker</c-text>
              </c-tab>
              <c-tab>
                <c-text>Feedback Ahli Gizi</c-text>
              </c-tab>
            </c-tab-list>
            <c-tab-panels>
              <c-tab-panel p="30">
                <TabDetailProgress :progress="progress" />
              </c-tab-panel>
              <c-tab-panel p="30">
                <TabFeedback />
              </c-tab-panel>
            </c-tab-panels>
          </c-tabs>
        </c-box>
      </c-flex>
    </c-box>
    <Footer />
  </c-box>
</template>

<script >
import Header from '@/components/header/index.vue';
import Breadcrumb from '@/components/breadcrumb';
import Footer from '@/components/layouts/footer/index.vue';
import { computed, inject, ref, watch } from '@vue/composition-api';
import { useRoute } from '@/utils/use-route';
import { useActions } from '@/stores';
import TabDetailProgress from '@/views/client/progress-tracker/detail/tab-detail-progress';
import TabFeedback from '@/views/client/progress-tracker/detail/tab-feedback';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const $route = useRoute();
  const $router = inject('$router');
  const progressId = computed(() => $route.value?.params?.progressId);
  const clientId = computed(() => $route.value?.params?.clientId);
  const breadcrumbItems = computed(() => [{
    label: 'Beranda',
    isCurrent: false,
    href: '/'
  }, {
    label: 'Progress & Tracker',
    href: $router.resolve({
      name: 'nutri.progress-tracker',
      params: {
        clientId: clientId.value
      }
    }).href
  }, {
    label: 'Detail Progress & Tracker',
    isCurrent: true
  }]);
  const {
    getProgressById
  } = useActions('clientProgressTracker', ['getProgressById', 'getProgressFeedbackById']);
  let progress = ref(null);
  watch(progressId, async progressId => {
    let progress_ = await getProgressById(progressId);
    progress.value = progress_;
  });
  return {
    breadcrumbItems,
    progress
  };
};

__sfc_main.components = Object.assign({
  Header,
  Breadcrumb,
  TabDetailProgress,
  TabFeedback,
  Footer
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
[data-chakra-component="CTabs"] {
  /*border: 1px solid var(--chakra-colors-primary-400);*/
}

[data-chakra-component="CTabList"] {
  border: none;
  display: flex;
  justify-content: space-around;
  color: #c4c4c4;
  background: #f2f2f2;
  border-radius: 5px;
  height: 60px;
}

::v-deep [data-chakra-component="CTab"] {
  margin: 0;
  width: 100%;
}

::v-deep [data-chakra-component="CTab"] {
  font-weight: bold;
  border-bottom: 1px solid var(--chakra-colors-primary-400);
  border-radius: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
  outline: none;
}

::v-deep [data-chakra-component="CTab"]:focus,
::v-deep [data-chakra-component="CTab"]:active {
  outline: none;
  box-shadow: none;
}

::v-deep [data-chakra-component="CTab"][aria-selected="true"] {
  color: var(--chakra-colors-primary-400);
  border: 1px solid var(--chakra-colors-primary-400);
  border-bottom-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold;
  background: white;
  outline: none;
}

::v-deep [data-chakra-component="CTabPanels"] {
  border: 1px solid var(--chakra-colors-primary-400);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
