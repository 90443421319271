<template>
  <c-box>
    <CardStep
      :current="currentStep"
      :total="2"
      :is-prev="currentStep > 1"
      :is-next="currentStep < 2"
      @prev="onPrev()"
      @next="onNext()"
    >
      <c-box v-if="currentStep === 1">
        <c-text font-size="2xl">
          Identitas Ahli Gizi
        </c-text>
        <c-form-control mt="30px">
          <c-form-label for="firstName">
            Nama Depan
          </c-form-label>
          <c-input
            type="text"
            name="firstName"
            :value="firstName"
            disabled
          />
        </c-form-control>
        <c-form-control mt="20px">
          <c-form-label for="lastName">
            Nama Belakang
          </c-form-label>
          <c-input
            type="text"
            name="lastName"
            :value="lastName"
            disabled
          />
        </c-form-control>
        <c-form-control mt="20px">
          <c-form-label for="title">
            Gelar
          </c-form-label>
          <c-input
            type="text"
            name="title"
            :value="title"
            disabled
          />
        </c-form-control>
        <c-form-control
          mt="20px"
          is-required
        >
          <c-form-label for="date">
            Tanggal &amp; waktu
          </c-form-label>
          <input
            v-model="date"
            v-chakra
            w="full"
            h="10"
            px="4"
            border-width="1px"
            border-color="gray.300"
            rounded="md"
            type="date"
            name="date"
            disabled
          >
        </c-form-control>
      </c-box>
      <c-box v-if="currentStep === 2">
        <c-text font-size="2xl">
          Feedback Notes
        </c-text>
        <c-form-control mt="30px">
          <c-form-label for="pola_makan">
            Masukan untuk pola makan
          </c-form-label>
          <textarea
            v-model="pola_makan"
            v-chakra
            rows="4"
            w="full"
            p="10px"
            border-width="1px"
            border-color="gray.300"
            rounded="md"
            name="pola_makan"
            disabled
          />
        </c-form-control>
        <c-form-control mt="20px">
          <c-form-label for="aktivitas_harian">
            Masukan untuk aktivitas harian (aktivitas fisik & olahraga)
          </c-form-label>
          <textarea
            v-model="aktivitas_harian"
            v-chakra
            rows="4"
            w="full"
            p="10px"
            border-width="1px"
            border-color="gray.300"
            rounded="md"
            name="aktivitas_harian"
            disabled
          />
        </c-form-control>
        <c-form-control mt="20px">
          <c-form-label for="gaya_hidup">
            Masukan untuk gaya hidup lainnya
          </c-form-label>
          <textarea
            v-model="gaya_hidup"
            v-chakra
            rows="4"
            w="full"
            p="10px"
            border-width="1px"
            border-color="gray.300"
            rounded="md"
            name="gaya_hidup"
            disabled
          />
        </c-form-control>
      </c-box>
    </CardStep>
  </c-box>
</template>

<script >
import CardStep from '@/views/nutritionists/progress-tracker/master/card-step.vue';
import { computed, ref, watch } from '@vue/composition-api';
import { useRoute } from '@/utils/use-route';
import { useRouter } from 'vue2-helpers/vue-router';
import { useActions } from '@/stores';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const $route = useRoute();
  const $router = useRouter();
  const progressId = computed(() => $route.value?.params.progressId);
  const currentStep = computed({
    get() {
      return +($route.value?.query.stepi ?? 1);
    },

    set(v) {
      $router.push({
        query: { ...$route.value?.query,
          stepi: v
        }
      });
    }

  });
  const {
    getProgressFeedbackById
  } = useActions('clientProgressTracker', ['getProgressFeedbackById']);
  let firstName = ref(null);
  let lastName = ref(null);
  let title = ref(null);
  let date = ref(null);
  let pola_makan = ref(null);
  let gaya_hidup = ref(null);
  let aktivitas_harian = ref(null); // const { user } = useState("auth", ["token", "user"])
  // const { getProfile } = useActions("profile", ["getProfile"])

  watch(progressId, async progressId => {
    if (progressId == null) return; // firstName.value = user.firstName
    // lastName.value = user.lastName
    // let profile = await getProfile()
    // title.value = profile.education[0].title

    let feedback = await getProgressFeedbackById(progressId);
    firstName.value = feedback.firstName;
    lastName.value = feedback.lastName;
    title.value = feedback.title;
    date.value = toDateInputValue(feedback.date);
    pola_makan.value = feedback.dietAdvice;
    gaya_hidup.value = feedback.lifestyleAdvice;
    aktivitas_harian.value = feedback.dailyActivityAdvice;
  });

  function onNext() {
    if (currentStep.value < 2) {
      currentStep.value++;
    }
  }

  function onPrev() {
    if (currentStep.value > 1) {
      currentStep.value--;
    }
  }

  function toDateInputValue(date) {
    date = new Date(date);
    const local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local?.toJSON()?.slice(0, 10);
  }

  return {
    currentStep,
    firstName,
    lastName,
    title,
    date,
    pola_makan,
    gaya_hidup,
    aktivitas_harian,
    onNext,
    onPrev
  };
};

__sfc_main.components = Object.assign({
  CardStep
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
input[disabled],
textarea[disabled] {
  background: #8080805e;
  opacity: 0.5;
}
</style>
